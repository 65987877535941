const data = [
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/kucuk-boy-30-adet-resim-316648_w300_.png",
    productType: "Giggles Adult Diapers Jumbo",
    productDesc: "Small 30pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/orta-boy-30-adet-resim-154700_w300_.png",
    productType: "Giggles Adult Diapers Jumbo",
    productDesc: "Medium 30pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/buyuk-boy-30-adet-resim-684473_w300_.png",
    productType: "Giggles Adult Diapers Jumbo",
    productDesc: "Large 30pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/extra-buyuk-boy-30-adet-resim-480570_w300_.png",
    productType: "Giggles Adult Diapers Jumbo",
    productDesc: "Extra Large 30pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/orta-boy-15-adet-resim-823425_w300_.png",
    productType: "Giggles Adult Diapers Eco",
    productDesc: "Medium 15pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/buyuk-boy-15-adet-resim-301208_w300_.png",
    productType: "Giggles Adult Diapers Eco",
    productDesc: "Large 15pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/extra-buyuk-boy-15-adet-resim-532438_w300_.png",
    productType: "Giggles Adult Diapers Eco",
    productDesc: "Extra Large 15pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/kucuk-boy-resim-688146_w300_.png",
    productType: "Giggles Adult Diapers Standard",
    productDesc: "Small 9pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/orta-boy-8-adet-resim-189990_w300_.png",
    productType: "Giggles Adult Diapers Standard",
    productDesc: "Medium 8pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/buyuk-boy-7-adet-resim-811008_w300_.png",
    productType: "Giggles Adult Diapers Standard",
    productDesc: "Large 7pcs",
    price: 10,
    brand: 'Giggles'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/kucuk-boy-resim-856552_w300_.png",
    productType: "Dr. Marco Adult Diapers Jumbo",
    productDesc: "Small 30pcs",
    price: 10,
    brand: 'Dr.Marco'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/orta-boy-resim-784822_w300_.png",
    productType: "Dr. Marco Adult Diapers Jumbo",
    productDesc: "Medium 30pcs",
    price: 10,
    brand: 'Dr.Marco'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/buyuk-boy-resim-978294_w300_.png",
    productType: "Dr. Marco Adult Diapers Jumbo",
    productDesc: "Large 30pcs",
    price: 10,
    brand: 'Dr.Marco'
  },
  {
    imgSrc:
      "https://www.sevinclersaglik.com/upload/faaliyet/temp/extra-buyuk-boy-resim-336582_w300_.png",
    productType: "Dr. Marco Adult Diapers Jumbo",
    productDesc: "Extra Large 30pcs",
    price: 10,
    brand: 'Dr.Marco'
  },
];

export default data;