const ListItem = (props) => {
  const { hasSevinclerURL, isLargeImg, imgSrc, productType, productDesc, piece, price } =
    props;

  const sevinclerImgStyle = {
    marginTop: -30,
    height: isLargeImg ? 130 : 150,
  };

  return (
    <div
      style={{
        position: "relative",
        width: 1000,
        height: 100,
        borderRadius: 15,
        backgroundColor: "white",
        display: "flex",
        boxShadow: "rgba(0, 0, 0, 0.1) 0px 4px 12px;",
        paddingLeft: 60,
        marginTop: 30
      }}
    >
      <div style={{width: 96}}>
        <img style={hasSevinclerURL ? sevinclerImgStyle : {}} src={imgSrc} />
      </div>
      <div>
        <p
          style={{
            fontSize: 20,
            fontWeight: 500,
            marginLeft:  50,
            marginBottom: 0,
          }}
        >
          {productType}
        </p>
        <p
          style={{
            fontSize: 25,
            fontWeight: 500,
            marginLeft: 50,
            marginTop: 0,
          }}
        >
          {productDesc}
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: 30,
            fontWeight: 500,
            marginLeft:  isLargeImg ? 77 : 50,
          }}
        >
          x{piece}
        </p>
      </div>
      <div>
        <p
          style={{
            fontSize: 30,
            fontWeight: "500",
            position: "absolute",
            right: 50,
          }}
        >
          ~{piece*price}$
        </p>
      </div>
    </div>
  );
};

export default ListItem;
