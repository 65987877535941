import Container from "@mui/material/Container";
import ListItem from "./components/ListItem";
import data from './data/data';


const LargeImages = ['Giggles Adult Diapers Eco', 'Giggles Adult Diapers Standard']

function App() {
  return (
    <>
      <Container>
        {data.map((props) => (
          <ListItem hasSevinclerURL {...props} piece={100} isLargeImg={LargeImages.includes(props.productType)}/>
        ))}
      </Container>
    </>
  );
}

export default App;
